.information {
  margin-left: 40px;
  @apply pt-25px;
  /*@apply w-full;*/
}

.title {
  font-weight: 500;
  font-size: 17px;
}

@media screen and (max-width: 1470px) {
  .information {
    margin-left: 0;
  }
}

@media (min-width: 1001px) and (max-width: 1470px) {
  .title {
    font-size: 30px;
  }
}

@media (max-width: 1000px) {
  .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 20px;
  }
}

.titleContainer {
  @apply text-black;
  font-size: 17px;
  font-weight: 700;
}

.information.tiny {
  padding-top: 20px;
  margin-left: 0;
}

.more svg path {
  fill: #E54E8D;
}

.more svg {
  width: 22px;
  height: 22px;
}

.more a {
  display: flex;
  align-items: center;
}

.information.tiny .titleContainer {
  font-size: 17px;
  font-weight: 400;
  line-height: 120%;
}

.properties {
  @apply my-20px;
}

@media (max-width: 480px) {
  .properties {
    margin-top: 25px;
  }
}

.warning {
  @apply text-product-info-warning;
  @apply mt-20px;
}

.warning span {
  @apply text-pink;
}

.more {
  @apply flex;
  @apply text-13px text-pink leading-15px;
  align-items: center;
}

.more > a div:hover, .more a:last-child:hover div:last-child {
  border-color: transparent;
}

.more > a:first-child, .more a:last-child div:last-child {
  border-bottom: 1px solid #E54E8D;
  line-height: 16px;
}

.more a:first-child {
  display: block;
  height: 17px;
}

.more a {
  text-decoration: none; }

.more > a:hover {
  border-color: transparent;
}

.more > a:not(:first-child) {
  @apply ml-16px;
}

.more.responsive {
  display: none;
}

.tags {
  @apply flex flex-wrap gap-x-8px gap-y-12px;
  @apply mt-20px;
  /*max-height: 86px;*/
  /*overflow: hidden;*/
}

@media (max-width: 1440px) {
  .information .titleContainer {
    font-size: 30px;
    font-weight: 500;
  }

  .information.tiny .titleContainer {
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
  }

}

@media (max-width: 1000px) {
  .information .titleContainer {
    margin-bottom: 25px;
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .information {
    max-width: calc(100vw - 40px);
  }

  .more.responsive {
    display: block;
  }

  .more {
    font-weight: 400;
    display: none;
  }

  .more a:last-child {
    display: none; }

  .more:hover {
    text-decoration: none;
  }

  .information .titleContainer {
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}