.property {
  @apply flex w-full;
}

.property:not(:first-child) {
  margin-top: 10px;
}

.property div:not(.dots):not(:first-child) {
  @apply ml-7px;
}

.name {
  @apply text-product-property-name border-b border-gray-300 leading-product-property-name;
  max-width: 202px;
  border-style: dotted;
}

.name span {
  padding-right: 5px;
  background-color: #fff;
  position: relative;
  bottom: -2px;
}

.dots {
  @apply flex-1 block border-b border-dashed border-gray-300 mt-15px h-1px;
  border-style: dotted;
  align-self: flex-end;
  position: relative;
}

.popover {
  padding: 10px;
  width: max-content;
  max-width: 300px;
  position: relative;
  @apply text-product-property-value leading-product-property-value;
}

.value {
  @apply self-end;
  @apply text-product-property-value leading-product-property-value;
  width:227px;
  @apply truncate;
}

@media (max-width: 1000px) {
  .property:not(:first-child) {
    margin-top: 11px;
  }

  .name {
    max-width: 250px;
  }
}



